<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="微信设置"
      class="px-5 py-3 mb-5"
    >
      <div class="title-tip">
        开通微信营销需要绑定微信公众帐号，不知道怎么绑定请参考 <a href="">开通指引</a>
        <br>更换主公众号后，会员进入店铺将成为新的会员，建议不要更换。
        <br>设置时，请将www.xmartjoy.com设为微信JS接口安全域名。<v-btn
          small
          color="warning"
          dark
        >
          操作步骤
        </v-btn>
      </div>
      <div class="floor">
        <v-row
          class="pa-4"
          style="color:#000;"
        >
          <div>上传微信验证文件：</div>
          <v-col cols="6">
            <v-file-input
              v-model="featureImage"
              multiple
              label="上传文件"
              outlined
            />
          </v-col>
        </v-row>
        <v-btn
          color="green"
          dark
          @click="uploadClick"
        >
          上传
        </v-btn>
      </div>
      <div class="floor">
        <v-row
          class="pa-4"
          style="color:#000;"
        >
          <div>ip白名单：</div>
          <div>
            111.231.2.113
            <br>36.42.72.220
            <br>36.42.72.7
            <br>36.42.72.219
          </div>
        </v-row>
        <span style="color:#666;">将IP地址复制到公众号后台 - 开发 - 基本配置 - IP白名单里。</span>
      </div>
      <div class="floor">
        <v-subheader style="font-size:15px;">
          公众号设置
        </v-subheader>
        <form>
          <v-text-field
            v-model="name"
            label="名称*"
            :error-messages="nameErrors"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          />
          <v-text-field
            v-model="id"
            label="原始ID*"
            :error-messages="idErrors"
            required
            @input="$v.id.$touch()"
            @blur="$v.id.$touch()"
          />
          <v-text-field
            v-model="wxNumber"
            label="微信号*"
            :error-messages="wxNumberErrors"
            required
            @input="$v.wxNumber.$touch()"
            @blur="$v.wxNumber.$touch()"
          />
          <v-row
            class="pa-4"
            style="color:#000;"
          >
            <div>回调URL：</div>
            <div>
              {{ redirectUrl }}
              <button
                color="green"
                class="tag-read"
                :data-clipboard-text="redirectUrl"
                @click="tokenCopyClick"
              >
                复制
              </button>
            </div>
          </v-row>
          <v-row
            class="pa-4"
            style="color:#000;"
          >
            <div>Token：</div>
            {{ token }}
            <button
              color="green"
              class="tag-read"
              :data-clipboard-text="token"
              @click="tokenCopyClick"
            >
              复制
            </button>
          </v-row>
          <v-text-field
            v-model="redirect"
            label="重定向地址*"
            :error-messages="redirectErrors"
            required
            @input="$v.redirect.$touch()"
            @blur="$v.redirect.$touch()"
          />
          <v-text-field
            v-model="appId"
            label="AppId*"
            :error-messages="appIdErrors"
            required
            @input="$v.appId.$touch()"
            @blur="$v.appId.$touch()"
          />
          <div @click="wxClick">
            点击获取（微信开发者）
          </div>
          <v-text-field
            v-model="secret"
            label="Secret*"
            :error-messages="secretErrors"
            required
            @input="$v.secret.$touch()"
            @blur="$v.secret.$touch()"
          />
          <div @click="wxClick">
            点击获取（微信开发者）
          </div>
          <div>公众号二维码:</div>
          <p>请到公众号后台 - 公众号设置里下载</p>
          <v-btn
            color="green"
            class="mr-4"
            @click="submit"
          >
            保存
          </v-btn>
        </form>
      </div>
    </base-material-card>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  import Clipboard from 'clipboard'
  export default {
    mixins: [validationMixin],
    validations: {
      name: { required },
      id: { required },
      wxNumber: { required },
      appId: { required },
      secret: { required },
      redirect: { required },
    },
    data () {
      return {
        featureImage: null,
        name: '',
        id: '',
        wxNumber: '',
        appId: '',
        secret: '',
        wxId: '', // 保存公众号id
        siteId: '',
        redirect: '', // 重定向地址
      }
    },
    computed: {
      redirectUrl () {
        var url
        if (this.appId) {
          url = 'https://zdwz.api.sdcom.gov.cn/wechat/' + this.appId
          return url
        } else {
          url = 'https://zdwz.api.sdcom.gov.cn/wechat/'
          return url
        }
      },
      token () {
        return this.getRandom()
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('名称不能为空')
        return errors
      },
      idErrors () {
        const errors = []
        if (!this.$v.id.$dirty) return errors
        !this.$v.id.required && errors.push('原始id不能为空')
        return errors
      },
      wxNumberErrors () {
        const errors = []
        if (!this.$v.wxNumber.$dirty) return errors
        !this.$v.wxNumber.required && errors.push('微信号不能为空')
        return errors
      },
      redirectErrors () {
        const errors = []
        if (!this.$v.redirect.$dirty) return errors
        !this.$v.redirect.required && errors.push('重定向地址不能为空')
        return errors
      },
      appIdErrors () {
        const errors = []
        if (!this.$v.appId.$dirty) return errors
        !this.$v.appId.required && errors.push('AppId不能为空')
        return errors
      },
      secretErrors () {
        const errors = []
        if (!this.$v.secret.$dirty) return errors
        !this.$v.secret.required && errors.push('Secret不能为空')
        return errors
      },
    },
    created () {
      this.getWxListApi()
    },
    methods: {
      getRandom () {
        var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        var num = ''
        for (var i = 0; i < 32; i++) {
          num += arr[parseInt(Math.random() * 36)]
        }
        return num
      },
      async getWxListApi () {
        await this.$axios.get('/wechat/configs', { params: { site: 11 } }).then(res => {
          console.log('res', res)
          // this.redirectUrl = res.data.data[0].redirectUrl
          // this.token = res.data.data[0].token
          this.wxId = res.data.data[0].id
          this.siteId = res.data.data[0].siteId
        }).catch(err => {
          console.log('err', err)
        })
      },
      uploadClick () {
        console.log('featureImage', this.featureImage)
      },
      // 复制回调URL
      urlCopyClick () {
        this.$swal({
          title: '复制成功',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      // token
      tokenCopyClick () {
        var clipboard = new Clipboard('.tag-read')
        clipboard.on('success', e => {
          this.$swal({
            title: '复制成功',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          // 释放内存
          clipboard.destroy()
        })
        clipboard.on('error', e => {
          // 不支持复制
          this.$swal({
            title: '该浏览器不支持自动复制',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          })
          // 释放内存
          clipboard.destroy()
        })
        // this.$swal({
        //   title: '复制成功',
        //   icon: 'success',
        //   showConfirmButton: false,
        //   timer: 1500,
        // })
      },
      // 保存
      submit () {
        console.log('ooo', this.$v.$touch())
        this.$v.$touch()
        var page = {
          appId: this.appId,
          appSecret: this.secret,
          deletedAt: null,
          id: this.wxId,
          redirectUrl: this.redirect,
          siteId: this.siteId,
          token: this.token,
          type: 'mp',
        }
        this.submitApi(page)
      },
      async submitApi (page) {
        await this.$axios.put(`/wechat/configs/${this.wxId}`, page).then(res => {
          console.log('res', res)
          if (res.code === 200) {
            this.$swal({
              title: '保存成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          }
        }).catch(err => {
          this.$swal({
            title: '报错失败',
            icon: 'error',
            text: '原因:' + err,
            confirmButtonText: '知道了',
          })
        })
      },
      // 点击获取（微信开发者）
      wxClick () {
        var url = 'https://mp.weixin.qq.com/'
        window.open(url, '_blank')
      },
    },
  }
</script>

<style lang="scss" scoped>
.main {
  margin-top: 0;
}
.form_box {
  max-height: 500px;
  overflow: auto;
}
.tree_box {
  max-height: 480px;
  overflow: auto;
}
.title-tip {
  padding: 10px;
  background-color: #e6f7ff;
  color: #666;
  margin-top: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}
.floor {
  padding: 10px;
  background-color: #f8f8f8;
  margin-top: 10px;
  line-height: 30px;
  margin-bottom: 10px;
}
.tag-read{
  display: inline-block;
  padding: 0px 10px;
  background-color: #4CAF50 ;
  color: #fff;
  border-radius: 4px;
  margin-left: 10px;
}
</style>
